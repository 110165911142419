import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AbstractFileHttpService, Attachment } from '@dougs/core/files';
import {
  ActiveCategory,
  Investment,
  InvestmentPost,
  InvestmentStat,
  InvestmentStatus,
  SearchInvestment,
} from '@dougs/investment/dto';

@Injectable({
  providedIn: 'root',
})
export class InvestmentHttpService extends AbstractFileHttpService {
  constructor(protected readonly http: HttpClient) {
    super();
  }

  protected sortedFileModelNameArray: string[] = ['companies', 'investments'];

  getInvestmentStat(companyId: number): Observable<InvestmentStat> {
    return this.http.get<InvestmentStat>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/investments/stats`,
    );
  }

  // TODO Put the arguments into the API
  getInvestments(
    companyId: number,
    limit: number,
    offset: number,
    hasFlagIsDraft: boolean,
    search?: SearchInvestment,
  ): Observable<Investment[]> {
    let params: HttpParams = new HttpParams()
      .append('isDraft', 'false')
      .append('limit', limit.toString())
      .append('offset', offset.toString())
      .append('orderBy', '["amortizationStartDate", "DESC"]')
      .append('orderBy', '["id","ASC"]');

    if (hasFlagIsDraft) {
      params = params.append('isDraft', 'true');
    }

    if (search) {
      Object.entries(search).forEach((value: [string, string | number]) => {
        if (value[0] === 'search' && value[1]) {
          params = params.append(`q`, value[1].toString());
        } else if (value[1]) {
          params = params.append(`q-${value[0]}`, value[1].toString());
        }
      });
    }

    return this.http.get<Investment[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/investments`, {
      params,
    });
  }

  getActiveCategories(companyId: number): Observable<ActiveCategory[]> {
    return this.http.get<ActiveCategory[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/investments/active-categories`,
    );
  }

  updateInvestment(investment: Investment): Observable<Investment> {
    return this.http.post<Investment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${investment.companyId}/investments/${investment.id}`,
      investment,
    );
  }

  createInvestment(companyId: number, investment: InvestmentPost): Observable<Investment> {
    return this.http.post<Investment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/investments`,
      investment,
    );
  }

  deleteInvestment(investment: Investment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${investment.companyId}/investments/${investment.id}`,
    );
  }

  deleteInvestmentAttachment(attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${attachment.companyId}/investments/${attachment.modelId}/attachments/${attachment.id}`,
    );
  }

  getInvestment(companyId: number, investmentId: number): Observable<Investment> {
    return this.http.get<Investment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/investments/${investmentId}`,
    );
  }

  getInvestmentStatus(companyId: number, investmentId: number): Observable<InvestmentStatus> {
    return this.http.get<InvestmentStatus>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/investments/${investmentId}/status`,
    );
  }
}
