import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Attachment, AttachmentService, StateAttachmentUtils } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService, StateUtilsService } from '@dougs/core/state';
import { FlashMessagesService } from '@dougs/ds';
import {
  ActiveCategory,
  Investment,
  InvestmentPost,
  InvestmentStat,
  InvestmentStatus,
  SearchInvestment,
} from '@dougs/investment/dto';
import { UserStateService } from '@dougs/user/shared';
import { InvestmentHttpService } from '../http/investment.http';

interface InvestmentState {
  investments: Investment[];
  investmentStats: InvestmentStat;
  activeCategories: ActiveCategory[];
}

const LIMIT = 5;

@Injectable({
  providedIn: 'root',
})
export class InvestmentStateService extends StateService<InvestmentState> {
  readonly investments$: Observable<Investment[]> = this.select<Investment[]>((state) => state.investments);
  readonly investmentStats$: Observable<InvestmentStat> = this.select<InvestmentStat>((state) => state.investmentStats);
  readonly activeCategories$: Observable<ActiveCategory[]> = this.select<ActiveCategory[]>(
    (state) => state.activeCategories,
  );
  public currentSearch?: SearchInvestment;

  constructor(
    private readonly investmentHttpService: InvestmentHttpService,
    private readonly logger: LoggerService,
    private readonly attachmentService: AttachmentService<Investment>,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly userStateService: UserStateService,
  ) {
    super();
  }

  private currentOffset = 0;

  async addInvestment(companyId: number, investment: InvestmentPost, files: File[]): Promise<Investment | null> {
    try {
      const investmentCreated: Investment = await lastValueFrom(
        this.investmentHttpService.createInvestment(companyId, investment),
      );

      await this.uploadFiles(investmentCreated, files);
      await this.refreshInvestments(companyId);
      await this.refreshInvestmentStat(companyId);
      await this.getActiveCategories(companyId);

      return investmentCreated;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.showErrorMessage(e);
      }
      this.logger.error(e);

      return null;
    }
  }

  async updateInvestment(investment: Investment, files: File[] = []): Promise<Investment | null> {
    try {
      const investmentUpdated: Investment = await lastValueFrom(
        this.investmentHttpService.updateInvestment(investment),
      );

      const filesUploaded: Attachment[] = await this.uploadFiles(investment, files);

      if (filesUploaded) {
        investmentUpdated.attachments = [...investmentUpdated.attachments, ...filesUploaded];
      }

      this.updateInvestmentState(investmentUpdated);

      await this.refreshInvestmentStat(investment.companyId);
      await this.getActiveCategories(investment.companyId);

      return investmentUpdated;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async updateInvestmentFullyPaid(investment: Investment, isFullyPaid: boolean): Promise<void> {
    try {
      const investmentToUpdate: Investment = {
        ...investment,
        isFullyPaid,
      };

      this.updateInvestmentState(investmentToUpdate);

      await lastValueFrom(
        this.investmentHttpService.updateInvestment({
          ...investment,
          isFullyPaid,
        }),
      );
    } catch (e) {
      this.showErrorMessage(e as HttpErrorResponse);
      this.updateInvestmentState(investment);
      this.logger.error(e);
    }
  }

  private showErrorMessage(e: HttpErrorResponse): void {
    if (e.error?.messageCodeInstance?.userMessage) {
      this.flashMessagesService.show(e.error?.messageCodeInstance?.userMessage, {
        timeout: 5000,
        type: 'error',
      });
    }
  }

  async uploadAttachments(investment: Investment, files: FileList): Promise<void> {
    try {
      const attachmentsMap: Map<string, File> = new Map<string, File>();
      const investmentWithTempAttachment: Investment = this.attachmentService.addAttachments(
        investment,
        StateAttachmentUtils.getModelWithTempAttachment(files, attachmentsMap, investment.companyId, investment.id),
      );

      this.updateInvestmentState(investmentWithTempAttachment);

      let investmentUpdated: Investment = investmentWithTempAttachment;
      for (const [uuid, file] of Array.from(attachmentsMap)) {
        try {
          const attachmentUploaded: Attachment = await lastValueFrom(
            this.investmentHttpService.uploadFile(file, {}, investment.companyId, investment.id),
          );
          investmentUpdated = this.attachmentService.replaceAttachment(
            investmentUpdated,
            StateAttachmentUtils.addHasBeenUploadedNowToAttachment(attachmentUploaded),
            uuid,
          );
          this.updateInvestmentState(investmentUpdated);
        } catch (e) {
          this.logger.error(e);
          investmentUpdated = StateAttachmentUtils.removeAttachmentByUUIDFromModel(investmentUpdated, uuid);
          this.updateInvestmentState(investmentUpdated);
        }
      }
    } catch (e) {
      this.updateInvestmentState(investment);
      this.logger.error(e);
    }
  }

  async updateMemo(investment: Investment, memo: string): Promise<void> {
    try {
      const investmentToUpdate: Investment = {
        ...investment,
        memo,
      };

      this.updateInvestmentState(investmentToUpdate);

      await lastValueFrom(this.investmentHttpService.updateInvestment(investmentToUpdate));
    } catch (e) {
      this.rollback();
      this.logger.error(e);
    }
  }

  async updateAskForSaleDate(investment: Investment, saleDate: string): Promise<Investment | null> {
    try {
      return await lastValueFrom(
        this.investmentHttpService.updateInvestment({
          ...investment,
          saleDate,
        }),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async removeInvestmentAttachment(investment: Investment, attachment: Attachment): Promise<void> {
    const previousAttachmentsIds: (number | string)[] = this.attachmentService.getPreviousAttachmentsIds(
      investment?.attachments ?? [],
      attachment,
    );
    try {
      const updatedInvestment: Investment = this.attachmentService.removeAttachment(investment, attachment.id);
      this.updateInvestmentState(updatedInvestment);
      await lastValueFrom(this.investmentHttpService.deleteInvestmentAttachment(attachment));
    } catch (e) {
      const currentInvestment: Investment | undefined = this.state?.investments?.find(
        (investmentIterated) => investmentIterated.id === investment.id,
      );
      if (currentInvestment) {
        this.updateInvestmentState(
          this.attachmentService.addAttachmentAfterPreviousIds(currentInvestment, attachment, previousAttachmentsIds),
        );
      }
      this.logger.error(e);
    }
  }

  async loadMoreInvestments(companyId: number): Promise<void> {
    try {
      this.currentOffset += 1;
      this.setState({
        investments: [
          ...this.state.investments,
          ...(await lastValueFrom(
            this.investmentHttpService.getInvestments(
              companyId,
              LIMIT,
              this.currentOffset * LIMIT,
              this.userStateService.loggedInUser.flags.includes('canMarkInvestmentAsDraft'),
              this.currentSearch,
            ),
          )),
        ],
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeInvestment(investment: Investment): Promise<boolean> {
    const investementIndex = this.state.investments.findIndex(
      (investmentIterated) => investmentIterated.id === investment.id,
    );
    try {
      this.setState({
        investments: StateUtilsService.filterInState(this.state.investments, investment),
      });

      await lastValueFrom(this.investmentHttpService.deleteInvestment(investment));

      return true;
    } catch (e) {
      this.logger.error(e);

      this.setState({
        investments: [
          ...this.state.investments.slice(0, investementIndex),
          investment,
          ...this.state.investments.slice(investementIndex),
        ],
      });

      return false;
    }
  }

  async getActiveCategories(companyId: number): Promise<void> {
    try {
      this.setState({
        activeCategories: await lastValueFrom(this.investmentHttpService.getActiveCategories(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshInvestments(companyId: number): Promise<void> {
    this.currentOffset = 0;

    try {
      this.setState({
        investments: await lastValueFrom(
          this.investmentHttpService.getInvestments(
            companyId,
            LIMIT,
            this.currentOffset,
            this.userStateService.loggedInUser.flags.includes('canMarkInvestmentAsDraft'),
            this.currentSearch,
          ),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshInvestmentStat(companyId: number): Promise<void> {
    try {
      this.setState({
        investmentStats: await lastValueFrom(this.investmentHttpService.getInvestmentStat(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async searchInvestments(companyId: number, search: SearchInvestment): Promise<void> {
    this.currentSearch = search;
    await this.refreshInvestments(companyId);
  }

  async getInvestment(companyId: number, investmentId: number): Promise<Investment | null> {
    try {
      return await lastValueFrom(this.investmentHttpService.getInvestment(companyId, investmentId));
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async getInvestmentStatus(investment: Investment): Promise<InvestmentStatus | null> {
    try {
      return await lastValueFrom(this.investmentHttpService.getInvestmentStatus(investment.companyId, investment.id));
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  private updateInvestmentState(investment: Investment): void {
    if (this.state?.investments?.length) {
      this.setState({
        investments: StateUtilsService.replaceInState<Investment>(this.state.investments, investment),
      });
    }
  }

  private async uploadFiles(investment: Investment, files: File[]): Promise<Attachment[]> {
    return await Promise.all(
      files.map((file: File) =>
        lastValueFrom(this.investmentHttpService.uploadFile(file, {}, investment.companyId, investment.id)),
      ),
    );
  }

  resetSearch(): void {
    this.currentSearch = undefined;
  }
}
